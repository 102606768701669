import { Text } from 'components/Text';
import { useEffect, useState } from 'react';
import Check from '../../../assets/icons/check';
import { Button } from 'components/Button';
import { deadlineOptions } from './options';
import { useStore } from 'context/store-context';
import { useMutation } from '@apollo/client';
import { UPDATE_ORDER_SETTINGS } from 'gql/mutations';
import { withNotification } from 'components/Notification';
import { apiErrorHandler, NotificationTypes } from 'utils';
import { Back } from 'components/Back';

export const DeadLine = withNotification(({ showNotification }: any) => {
  const { storeProperties, refetchStoreProperties } = useStore();
  const deadlineOption = storeProperties?.orderSettings?.deadline;

  const [storeDeadlineOption, setStoreDeadlineOption] = useState({
    timing: '',
    description: '',
  });

  const [updateOrderSetting, { loading }] = useMutation(UPDATE_ORDER_SETTINGS, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (deadlineOption) setStoreDeadlineOption(deadlineOption);
  }, [deadlineOption]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStoreDeadlineOption((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = (key: string, value: string) => {
    if (!loading) {
      const newValue = { ...storeDeadlineOption, [key]: value };
      setStoreDeadlineOption(newValue);
      updateOrderSetting({
        variables: {
          storeId: storeProperties?.id,
          orderSetting: {
            deadline: newValue,
          },
        },
      });
      refetchStoreProperties && refetchStoreProperties();
    }
  };

  return (
    <div>
      <div className="flex flex-col mx-10 gap-5">
        <div className="mb-2 flex items-center gap-2">
          <Back className="mt-2" />
          <Text value="Editing Deadline" type="h1" />
        </div>

        <Text
          value="Define how much time customers have to edit their orders."
          type="h3"
        />
        <div className="option-selector">
          {deadlineOptions.map((option) => (
            <div
              key={option.id}
              className={`option ${
                loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
              } ${storeDeadlineOption.timing === option.id ? 'selected' : ''}`}
              onClick={() => handleClick('timing', option.id)}
            >
              <div
                className={`absolute top-3 right-3 ${
                  storeDeadlineOption.timing === option.id ? '' : 'hidden'
                }`}
              >
                <Check className="text-[#2563eb]" />
              </div>
              <div className="label">{option.label}</div>
              <div className="description">{option.description}</div>
            </div>
          ))}
        </div>

        <Text
          value="Describe your shop's average fulfillment time."
          type="h3"
        />
        <div>
          <div className="flex items-center gap-2">
            <input
              name="description"
              type="text"
              id="fulfillment-time"
              value={storeDeadlineOption.description}
              onChange={handleChange}
              placeholder="You can edit the order until tomorrow."
            />
            <Button
              disabled={loading}
              isRounded
              onClick={() =>
                handleClick('description', storeDeadlineOption.description)
              }
            >
              Save
            </Button>
          </div>
          <Text
            value="Describe how quickly the customer should expect their order to ship."
            type="sm"
            className="text-gray-500"
          />
        </div>
      </div>
    </div>
  );
});
