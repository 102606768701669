enum DeadlineTiming {
  THIRTY_MIN = 'THIRTY_MIN',
  SIXTY_MIN = 'SIXTY_MIN',
  NINETY_MIN = 'NINETY_MIN',
  TWO_HOURS = 'TWO_HOURS',
  THREE_HOURS = 'THREE_HOURS',
  SIX_HOURS = 'SIX_HOURS',
  TWELVE_HOURS = 'TWELVE_HOURS',
  ONE_DAY = 'ONE_DAY',
  TWO_DAYS = 'TWO_DAYS',
  THREE_DAYS = 'THREE_DAYS',
  FIVE_DAYS = 'FIVE_DAYS',
  TEN_DAYS = 'TEN_DAYS',
}

export const deadlineOptions = [
  {
    id: DeadlineTiming.THIRTY_MIN,
    label: '30 minutes',
    description: 'After created',
  },
  {
    id: DeadlineTiming.SIXTY_MIN,
    label: '60 minutes',
    description: 'After created',
  },
  {
    id: DeadlineTiming.NINETY_MIN,
    label: '90 minutes',
    description: 'After created',
  },
  {
    id: DeadlineTiming.TWO_HOURS,
    label: '2 hours',
    description: 'After created',
  },
  {
    id: DeadlineTiming.THREE_HOURS,
    label: '3 hours',
    description: 'After created',
  },
  {
    id: DeadlineTiming.SIX_HOURS,
    label: '6 hours',
    description: 'After created',
  },
  {
    id: DeadlineTiming.TWELVE_HOURS,
    label: '12 hours',
    description: 'After created',
  },
  { id: DeadlineTiming.ONE_DAY, label: '1 day', description: 'After created' },
  {
    id: DeadlineTiming.TWO_DAYS,
    label: '2 days',
    description: 'After created',
  },
  {
    id: DeadlineTiming.THREE_DAYS,
    label: '3 days',
    description: 'After created',
  },
  {
    id: DeadlineTiming.FIVE_DAYS,
    label: '5 days',
    description: 'After created',
  },
  {
    id: DeadlineTiming.TEN_DAYS,
    label: '10 days',
    description: 'After created',
  },
];

export const getOptionById = (id: string) => {
  return deadlineOptions.find((option) => option.id === id)?.label || id; //if id is not found, it will return the same word
};

export const editShippingOptions = [
  {
    id: 'allow_customer_to_edit_shipping',
    title: 'Allow customers to edit their shipping',
    description:
      'All new addresses are verified with Google Maps, free of charge.',
  },
  {
    id: 'allow_customer_to_use_po_boxes',
    title: 'Allow customers to use PO boxes for shipping',
    description: 'Customers can change their shipping information to a PO Box.',
  },
  {
    id: 'allow_gmaps_to_verify_addresses',
    title: 'Allow Google Maps to verify shipping addresses',
    description:
      'Prompt customers to add their apartment number if there is an obvious mistake in their address which will result in a lost order.',
  },
];
