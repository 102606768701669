import { Routes, Route, Navigate } from 'react-router-dom';
import { NotFound } from 'containers/NotFound';
import { DeadLine } from './DeadLine';
import AllOrderSettings from './index';
import { ShippingEdit } from './ShippingEdit';

export const OrderSettings = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"></div>
      <Routes>
        <Route
          index
          element={<Navigate to="/order-settings/all" replace={true} />}
        />
        <Route path="/all" element={<AllOrderSettings />} />
        <Route path="/deadline" element={<DeadLine />} />
        <Route path="/shipping-edit" element={<ShippingEdit />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};
