import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';
import { useEffect, useState } from 'react';
import { editShippingOptions } from './options';
import { useStore } from 'context/store-context';
import { UPDATE_ORDER_SETTINGS } from 'gql/mutations';
import { useMutation } from '@apollo/client';
import { apiErrorHandler, NotificationTypes } from 'utils';
import { withNotification } from 'components/Notification';
import { Back } from 'components/Back';

export const ShippingEdit = withNotification(({ showNotification }: any) => {
  const { storeProperties, refetchStoreProperties } = useStore();
  const shippingOption = storeProperties?.orderSettings?.shipping;

  const [storeShippingSettings, setStoreShippingSettings] = useState<any>({
    allow_customer_to_edit_shipping: false,
    allow_customer_to_use_po_boxes: false,
    allow_gmaps_to_verify_addresses: false,
  });

  const [updateOrderSetting, { loading }] = useMutation(UPDATE_ORDER_SETTINGS, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    if (shippingOption) setStoreShippingSettings(shippingOption);
  }, [shippingOption]);

  const handleChange = (value: string, status: boolean) => {
    if (!loading) {
      const newValue = { ...storeShippingSettings, [value]: status };
      setStoreShippingSettings(newValue);
      updateOrderSetting({
        variables: {
          storeId: storeProperties?.id,
          orderSetting: {
            shipping: newValue,
          },
        },
      });
      refetchStoreProperties && refetchStoreProperties();
    }
  };

  return (
    <div>
      <div className="flex flex-col ml-10 gap-5">
        <div className="mb-2 flex items-center gap-2">
          <Back className="mb-2" />
          <div>
            <Text value="Addresss Verification" type="h1" />
            <Text
              value="Allow customers to edit their shipping address."
              type="sm"
              className="text-gray-500"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Text value="Editing Modules" type="h3" />
          {editShippingOptions.map((option, index) => (
            <div
              key={`shipping-edit-${index}`}
              className="py-2 flex w-fit justify-between gap-5 border-b border-gray-200  "
            >
              <Toggle
                disabled={loading}
                isEnabled={storeShippingSettings[option.id]}
                onChange={(status) => handleChange(option.id, status)}
                isSmall
              />
              <div>
                <Text value={option.title} type="h4" />
                <Text
                  value={option.description}
                  type="body"
                  className="text-gray-500"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
