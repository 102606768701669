import { gql } from 'graphql-tag';

export const UPDATE_USER_INFO = gql`
  mutation updateUser($id: uuid!, $userObj: users_set_input) {
    updateUser(pk_columns: { id: $id }, _set: $userObj) {
      id
    }
  }
`;

export const POST_INSTALL_REDIRECT = gql`
  mutation HandleRedirect(
    $code: String!
    $name: String
    $store: String!
    $searchParams: JSON!
  ) {
    handleInstallRedirect(
      input: {
        code: $code
        name: $name
        store: $store
        type: SHOPIFY
        searchParams: $searchParams
      }
    ) {
      id
      store
    }
  }
`;

export const GENERATE_INSTALL_LINK = gql`
  mutation GenerateInstall($store: String!) {
    generateInstallLink(input: { store: $store, type: SHOPIFY })
  }
`;

/*
  **
  store mutations
  **
*/

export const UPDATE_USER_STORE_NOTIFICATION = gql`
  mutation updateStoreUserNotification(
    $userId: uuid!
    $storeId: uuid!
    $claimNew: Boolean
    $claimRefund: Boolean
    $claimStatus: Boolean
    $claimReorder: Boolean
  ) {
    updateStoreUserNotification(
      pk_columns: { userId: $userId, storeId: $storeId }
      _set: {
        claimNew: $claimNew
        claimRefund: $claimRefund
        claimStatus: $claimStatus
        claimReorder: $claimReorder
      }
    ) {
      id
      userId
      storeId
      claimNew
      claimRefund
      claimStatus
      claimReorder
    }
  }
`;

export const CREATE_USER_STORE_NOTIFICATION = gql`
  mutation insertStoreUserNotification(
    $storeId: uuid!
    $userId: uuid!
    $claimNew: Boolean
    $claimRefund: Boolean
    $claimStatus: Boolean
    $claimReorder: Boolean
  ) {
    insertStoreUserNotification(
      object: {
        storeId: $storeId
        userId: $userId
        claimNew: $claimNew
        claimRefund: $claimRefund
        claimStatus: $claimStatus
        claimReorder: $claimReorder
      }
    ) {
      id
      userId
      storeId
      claimNew
      claimRefund
      claimStatus
      claimReorder
    }
  }
`;

export const INVITE_STORE_USER = gql`
  mutation insertStoreUserInvite($inviteObj: store_user_invites_insert_input!) {
    insertStoreUserInvite(object: $inviteObj) {
      id
      email
      role
    }
  }
`;

export const REMOVE_STORE_USER = gql`
  mutation deleteStoreUsers($storeId: uuid!, $userId: uuid!) {
    deleteStoreUsers(
      where: { userId: { _eq: $userId }, storeId: { _eq: $storeId } }
    ) {
      affected_rows
    }
  }
`;

export const ACCEPT_STORE_INVITE = gql`
  mutation acceptStoreInvite($input: MutationAcceptStoreInviteInput!) {
    acceptStoreInvite(input: $input) {
      id
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation updateStore($set: stores_set_input, $storeId: uuid!) {
    updateStore(pk_columns: { id: $storeId }, _set: $set) {
      id
      name
      contactPhone
      policyUrl
      requireDeliveryGuaranteePolicy
      completedSteps
      resolvePortalOptions
    }
  }
`;

export const UPDATE_PROTECTION_SETTINGS = gql`
  mutation setProtectionSettings($input: MutationSetProtectionSettingsInput!) {
    setProtectionSettings(input: $input)
  }
`;

export const CANCEL_STORE_PLAN = gql`
  mutation cancelStorePlan($input: MutationCancelStorePlanInput!) {
    cancelStorePlan(input: $input)
  }
`;

export const INSERT_STORE_USER_NOTIFICATIONS = gql`
  mutation insertStoreUserNotification(
    $object: store_user_notifications_insert_input!
  ) {
    insertStoreUserNotification(
      object: $object
      on_conflict: {
        constraint: user_store_notifications_pkey
        update_columns: [claimNew, claimRefund, claimReorder, claimStatus]
      }
    ) {
      claimNew
    }
  }
`;

export const REMOVE_USER_STORE_NOTIFICATIONS = gql`
  mutation deleteStoreUserNotifications($userId: uuid!, $storeId: uuid!) {
    deleteStoreUserNotification(userId: $userId, storeId: $storeId) {
      id
    }
  }
`;

export const UPDATE_STORE_CUSTOMER_AUTO_OPT_IN_TAGS = gql`
  mutation saveTagsExcludedCustomersAutoOptIn(
    $input: MutationSaveTagsExcludedCustomersAutoOptInInput!
  ) {
    saveTagsExcludedCustomersAutoOptIn(input: $input)
  }
`;

/*
  **
    claim mutations
  **
*/

export const REORDER_CLAIM = gql`
  mutation reorderClaim($input: [ClaimReorderInput!]!) {
    reorderClaim(input: $input) {
      id
      orderUrl
    }
  }
`;

export const START_CLAIM = gql`
  mutation startClaim($input: [ClaimInput!]!) {
    startClaim(input: $input) {
      id
    }
  }
`;

export const OTHER_CLAIM = gql`
  mutation otherClaim($input: [ClaimOtherInput!]!) {
    otherClaim(input: $input) {
      id
    }
  }
`;

export const REFUND_CLAIM = gql`
  mutation refundClaim($input: [ClaimRefundInput!]!) {
    refundClaim(input: $input) {
      id
    }
  }
`;

export const DENY_CLAIM = gql`
  mutation denyClaim($input: [ClaimDenyInput!]!) {
    denyClaim(input: $input) {
      id
    }
  }
`;

export const SET_STORE_PLAN = gql`
  mutation setPlan($storeId: String!) {
    setStorePlan(input: { storeId: $storeId }) {
      chargeUrl
    }
  }
`;

export const INSERT_CLAIM_IMAGE = gql`
  mutation InsertClaimImage($object: claim_images_insert_input!) {
    insertClaimImage(object: $object) {
      id
      claim {
        id
      }
    }
  }
`;

export const INSERT_CLAIM_IMAGES = gql`
  mutation InsertClaimImages($objects: [claim_images_insert_input!]!) {
    insertClaimImages(objects: $objects) {
      returning {
        claimId
        claim {
          createdAt
          date
          id
          notes
          number
          orderId
          status
          storeId
          tags
          reason
          updatedAt
          total
          createdBy {
            displayName
            id
          }
          images(order_by: { createdAt: desc }, limit: 5) {
            id
            url
          }
          videos(order_by: { createdAt: desc }, limit: 5) {
            id
            url
          }
          items {
            id
            reason
            total
            orderItem {
              imageSrc
              price
              subtotal
              taxTotal
              total
              sku
              quantity
              id
              title
              fulfillmentStatus
              fulfilledAt
              trackingUrl
              trackingId
              variantId
              deliveredAt
              estimatedDeliveredAt
            }
          }
          resolution
          settlements {
            id
            resolution
            notes
            metadata
            message
            items {
              id
              claimItem {
                orderItem {
                  price
                  quantity
                  sku
                }
              }
            }
            updatedBy {
              displayName
              email
              id
              avatarUrl
            }
            updatedAt
          }
          order {
            number
            customerName
            customerEmail
            customerPhone
            tax
            total
            protectionStatus
            protectionTotal
            createdAt
            subtotal
            items {
              updatedAt
              total
              title
              taxTotal
              tax
              subtotal
              sku
              quantity
              price
              orderId
              imageSrc
              id
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_CLAIM_IMAGE = gql`
  mutation deleteClaimImage($id: uuid!) {
    deleteClaimImage(id: $id) {
      claimId
      claim {
        createdAt
        date
        id
        notes
        number
        orderId
        status
        storeId
        tags
        reason
        updatedAt
        total
        createdBy {
          displayName
          id
        }
        images(order_by: { createdAt: desc }, limit: 5) {
          id
          url
        }
        videos(order_by: { createdAt: desc }, limit: 5) {
          id
          url
        }
        items {
          id
          reason
          total
          orderItem {
            imageSrc
            price
            subtotal
            taxTotal
            total
            sku
            quantity
            id
            title
            fulfillmentStatus
            fulfilledAt
            trackingUrl
            trackingId
            variantId
            deliveredAt
            estimatedDeliveredAt
          }
        }
        resolution
        settlements {
          id
          resolution
          notes
          metadata
          message
          items {
            id
            claimItem {
              orderItem {
                price
                quantity
                sku
              }
            }
          }
          updatedBy {
            displayName
            email
            id
            avatarUrl
          }
          updatedAt
        }
        order {
          number
          customerName
          customerEmail
          customerPhone
          tax
          total
          protectionStatus
          protectionTotal
          createdAt
          subtotal
          items {
            updatedAt
            total
            title
            taxTotal
            tax
            subtotal
            sku
            quantity
            price
            orderId
            imageSrc
            id
            createdAt
          }
        }
      }
    }
  }
`;

export const INSERT_CLAIM_VIDEOS = gql`
  mutation InsertClaimVideos($objects: [claim_videos_insert_input!]!) {
    insertClaimVideos(objects: $objects) {
      returning {
        claimId
        claim {
          createdAt
          date
          id
          notes
          number
          orderId
          status
          storeId
          tags
          reason
          updatedAt
          total
          createdBy {
            displayName
            id
          }
          images(order_by: { createdAt: desc }, limit: 5) {
            id
            url
          }
          videos(order_by: { createdAt: desc }, limit: 5) {
            id
            url
          }
          items {
            id
            reason
            total
            orderItem {
              imageSrc
              price
              subtotal
              taxTotal
              total
              sku
              quantity
              id
              title
              fulfillmentStatus
              fulfilledAt
              trackingUrl
              trackingId
              variantId
              deliveredAt
              estimatedDeliveredAt
            }
          }
          resolution
          settlements {
            id
            resolution
            notes
            metadata
            message
            items {
              id
              claimItem {
                orderItem {
                  price
                  quantity
                  sku
                }
              }
            }
            updatedBy {
              displayName
              email
              id
              avatarUrl
            }
            updatedAt
          }
          order {
            number
            customerName
            customerEmail
            customerPhone
            tax
            total
            protectionStatus
            protectionTotal
            createdAt
            subtotal
            items {
              updatedAt
              total
              title
              taxTotal
              tax
              subtotal
              sku
              quantity
              price
              orderId
              imageSrc
              id
              createdAt
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_CLAIM_VIDEO = gql`
  mutation deleteClaimVideo($id: uuid!) {
    deleteClaimVideo(id: $id) {
      claimId
      claim {
        createdAt
        date
        id
        notes
        number
        orderId
        status
        storeId
        tags
        reason
        updatedAt
        total
        createdBy {
          displayName
          id
        }
        images(order_by: { createdAt: desc }, limit: 5) {
          id
          url
        }
        videos(order_by: { createdAt: desc }, limit: 5) {
          id
          url
        }
        items {
          id
          reason
          total
          orderItem {
            imageSrc
            price
            subtotal
            taxTotal
            total
            sku
            quantity
            id
            title
            fulfillmentStatus
            fulfilledAt
            trackingUrl
            trackingId
            variantId
            deliveredAt
            estimatedDeliveredAt
          }
        }
        resolution
        settlements {
          id
          resolution
          notes
          metadata
          message
          items {
            id
            claimItem {
              orderItem {
                price
                quantity
                sku
              }
            }
          }
          updatedBy {
            displayName
            email
            id
            avatarUrl
          }
          updatedAt
        }
        order {
          number
          customerName
          customerEmail
          customerPhone
          tax
          total
          protectionStatus
          protectionTotal
          createdAt
          subtotal
          items {
            updatedAt
            total
            title
            taxTotal
            tax
            subtotal
            sku
            quantity
            price
            orderId
            imageSrc
            id
            createdAt
          }
        }
      }
    }
  }
`;

/*
  **
    billing mutations
  **
*/

/************
 *
 * ADMIN mutations
 * **********
 */

export const INVITE_ADMIN_USER = gql`
  mutation insertUser($inviteObj: users_insert_input!) {
    insertUser(object: $inviteObj) {
      id
      email
      displayName
      defaultRole
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($userId: uuid!, $role: String!) {
    updateUser(pk_columns: { id: $userId }, _set: { defaultRole: $role }) {
      id
      email
      displayName
      defaultRole
    }
    insertAuthUserRole(
      object: { userId: $userId, role: $role }
      on_conflict: {
        constraint: user_roles_user_id_role_key
        update_columns: [role]
      }
    ) {
      id
      role
    }
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation addAnnouncement($text: String!, $isActive: Boolean!) {
    insert_announcements_one(
      object: { text: $text, active: $isActive, type: MAIN_HEADER }
    ) {
      id
      text
      createdAt
      active
    }
  }
`;

export const UPDATE_ANNOUNCEMENT_ACTIVE_STATUS = gql`
  mutation updateAnnouncementActiveStatus($active: Boolean!, $ids: [uuid!]) {
    update_announcements(
      where: { id: { _in: $ids } }
      _set: { active: $active }
    ) {
      returning {
        active
        id
      }
    }
  }
`;

export const DELETE_ANNOUNCEMENTS = gql`
  mutation deleteAnnouncement($ids: [uuid!]) {
    delete_announcements(where: { id: { _in: $ids } }) {
      returning {
        id
      }
    }
  }
`;

/*
  **
    Tracking and Email Notifications
  **
*/

export const TEST_EMAIL_NOTIFICATION = gql`
  mutation testEmailNotification($input: MutationTestEmailNotificationInput!) {
    testEmailNotification(input: $input)
  }
`;

export const UPDATE_TRACKING_TEMPLATE = gql`
  mutation saveTrackingTemplate($input: MutationSaveTrackingTemplateInput!) {
    saveTrackingTemplate(input: $input) {
      active
      isTrackingDefault
      createdAt
      eventType
      id
      platformIntegrationType
      sentCount
      storeId
      subject
      templateHtml
      templateJson
      updatedAt
      usage
    }
  }
`;

/*
  **
    Brand Email
  **
*/

export const VERIFY_EMAIL_DOMAIN = gql`
  mutation verifyEmailDomain($input: MutationVerifyEmailDomainInput!) {
    verifyEmailDomain(input: $input) {
      validations {
        senderIndentityCreated
        senderEmail {
          freeProvider
          message
        }
        replyToEmail {
          freeProvider
          message
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER_NOTIFICATION_SETTINGS = gql`
  mutation saveCustomerNotificationsSettings(
    $input: MutationSaveCustomerNotificationsSettingsInput!
  ) {
    saveCustomerNotificationsSettings(input: $input) {
      id
      replyToEmail
      replyToName
      senderEmail
      senderName
    }
  }
`;

export const RESET_CUSTOMER_NOTIFICATION_SETTINGS = gql`
  mutation resetCustomerNotificationsSettings(
    $input: MutationResetCustomerNotificationsSettingsInput!
  ) {
    resetCustomerNotificationsSettings(input: $input)
  }
`;

/*
  **
    Install Request
  **
*/
export const SEND_INSTALL_REQUEST = gql`
  mutation sendInstallationRequestEmail(
    $input: MutationSendInstallationRequestEmailInput!
  ) {
    sendInstallationRequestEmail(input: $input)
  }
`;

/*
  **
    Custom Domain and Cover Image for Tracking Page
  **
*/

export const UPDATE_STORE_TRACKING_PAGE_SETTINGS = gql`
  mutation saveStoresSettings($input: MutationSaveStoresSettingsInput!) {
    saveStoresSettings(input: $input) {
      customDomain
      trackingPortalCoverImage
    }
  }
`;

export const RESET_STORE_TRACKING_PAGE_SETTINGS = gql`
  mutation resetStoreSettings($input: MutationResetStoreSettingsInput!) {
    resetStoreSettings(input: $input)
  }
`;

/*
  **
    Claim Automation Rules
  **
*/
export const UPDATE_CLAIM_AUTOMATION_RULE = gql`
  mutation saveClaimRules($input: MutationSaveClaimRulesInput!) {
    saveClaimRules(input: $input) {
      id
      storeId
      title
      statements
      active
      priority
      action
      actionNotes
      refundShipping
      ruleBeforeTransform
    }
  }
`;

export const REMOVE_CLAIM_AUTOMATION_RULE = gql`
  mutation deleteClaimAutomationRules(
    $input: MutationDeleteClaimAutomationRulesInput!
  ) {
    deleteClaimAutomationRules(input: $input) {
      id
      storeId
      title
      statements
      active
      priority
      action
      actionNotes
      refundShipping
      ruleBeforeTransform
    }
  }
`;

export const UPDATE_CLAIM_AUTOMATION_RULE_PRIORITY = gql`
  mutation changeClaimRulePriority(
    $input: MutationChangeClaimRulePriorityInput!
  ) {
    changeClaimRulePriority(input: $input) {
      id
      storeId
      title
      statements
      active
      priority
      action
      actionNotes
      refundShipping
      ruleBeforeTransform
    }
  }
`;

export const CANCEL_RESOLUTION = gql`
  mutation cancelResolution($input: [CancelResolutionResolveInput!]!) {
    cancelResolution(input: $input) {
      id
    }
  }
`;

export const UPDATE_ORDER_SETTINGS = gql`
  mutation orderSetting(
    $storeId: uuid!
    $orderSetting: order_settings_set_input!
  ) {
    updateOrderSettings(
      where: { storeId: { _eq: $storeId } }
      _set: $orderSetting
    ) {
      affected_rows
    }
  }
`;
