import { Button } from 'components/Button';
import { Text } from 'components/Text';
import ShippingImg from '../../../assets/images/google-map.png';
import DeadlineImg from '../../../assets/images/calendar-date.png';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getOptionById } from './options';
import { useStore } from 'context/store-context';
const OrderEditing = () => {
  const navigate = useNavigate();
  const { storeProperties } = useStore();
  const { deadline, shipping } = storeProperties?.orderSettings || {
    deadline: { timing: 'THIRTY_MIN', description: '' },
    shipping: { allow_customer_to_edit_shipping: false },
  };

  const orderSettingsList = [
    {
      text: 'Shipping Address Edits',
      subText:
        'Allow customers to edit their shipping address and benefit from a free address verification.',
      label: 'Shipping img',
      imgSrc: ShippingImg,
      href: 'shipping-edit',
      header: Object.values(shipping).includes(true) ? 'On' : 'Off',
      isSwitch: true,
      status: Object.values(shipping).includes(true),
    },
    {
      text: 'Editing Deadline',
      subText:
        'Set a time limit on the duration customers have after placing an order to make edits.',
      label: 'Deadline Img',
      imgSrc: DeadlineImg,
      href: 'deadline',
      header: deadline.timing,
      isSwitch: false,
      status: true,
    },
  ];

  const RenderBadge = (text: string, isToggle: boolean, status: boolean) => {
    let statusStyle = 'bg-green-100 text-green-800';

    if (!status) {
      statusStyle = 'bg-red-100 text-red-800';
    }

    return (
      <div
        className={classNames(
          isToggle ? statusStyle : 'bg-blue-100 text-blue-800',
          'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
        )}
      >
        {text}
      </div>
    );
  };

  return (
    <div>
      <div className="flex flex-col mx-10 gap-5">
        <div className="mb-2">
          <Text value="Order Editing Settings" type="h1" />
        </div>
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
              <div className={'shadow border-b border-gray-200 sm:rounded-lg'}>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50"></thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {orderSettingsList.map((setting, index) => (
                      <tr
                        className="table-data-row"
                        key={`order-settings-${index}`}
                      >
                        <td className="px-1 py-1 whitespace-nowrap text-gray-600 text-sm font-medium text-center">
                          <img
                            style={{ width: 50, height: 50 }}
                            className="inline-block rounded-full"
                            src={setting.imgSrc ?? ''}
                            alt={setting.label ?? ''}
                            referrerPolicy="no-referrer"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium">
                          <div className="flex gap-5">
                            <Text
                              value={setting.text}
                              type="bold"
                              className="text-lg"
                            />
                            {RenderBadge(
                              getOptionById(setting.header),
                              setting.isSwitch,
                              setting.status,
                            )}
                          </div>
                          <Text
                            value={setting.subText}
                            type="sm"
                            className="text-gray-400"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-sm font-medium text-center">
                          <Button
                            isRounded
                            onClick={() => {
                              navigate(`/order-settings/${setting.href ?? ''}`);
                            }}
                          >
                            Manage
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderEditing;
