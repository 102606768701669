import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Dashboard } from './NestedContainers/Dashboard';
import { Claims } from './NestedContainers/Claims';
import { Orders } from './NestedContainers/Orders';
import { Settings } from './NestedContainers/Settings';
import { OrderSettings } from './NestedContainers/OrderEditing/OrderSettings';
import { Billings } from './NestedContainers/Billings';
import { NotFound } from 'containers/NotFound';
import { SideBar } from 'components/Sidebar';
import { Components } from './NestedContainers/Components';
import { MenuIcon } from '@heroicons/react/outline';
import { NewClaim } from './NestedContainers/NewClaim';
import { useStore } from 'context/store-context';
import { SingleClaim } from './NestedContainers/Claim';
import { OnboardingWizard } from 'components/OnboardingWizard';
import { useMutation } from '@apollo/client';
import { withNotification } from 'components/Notification';
import { UPDATE_STORE } from 'gql/mutations';
import { NotificationTypes, apiErrorHandler } from 'utils';
import { AnnouncementHeader } from 'components/AnnouncementHeader';
import { Button } from 'components/Button';
import { SizeEnum } from 'utils';
import { useUser } from 'context/user-context';
import { userRoles } from 'types/user';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { MerchantChargeModal } from 'components/MerchantChargeModal';
import { UpdateAppInstallModal } from 'components/UpdateAppInstallModal';
import { ReferralProgram } from './NestedContainers/ReferralProgram';

export const MerchantApp: React.FC = withNotification(
  ({ showNotification }: any) => {
    const adminData = localStorage.getItem(
      process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
    );
    let adminContext: any;
    if (adminData) adminContext = JSON.parse(adminData);
    const userContext = useUser();
    const storeContext = useStore();
    const location = useLocation();
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [isChargeModalOpen, setIsChargeModalOpen] = useState<null | boolean>(
      null,
    );
    const [isUpdateAppInstallModalOpen, setIsUpdateAppInstallModalOpen] =
      useState(false);
    const [updateStore] = useMutation(UPDATE_STORE, {
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    });

    const userStore = storeContext.userStores?.find(
      (userStore) => storeContext.storeId === userStore.storeId,
    );

    const completeStoreOnboarding = () => {
      updateStore({
        optimisticResponse: {
          updateStore: {
            id: userStore?.storeId,
            ...userStore,
            completedSteps: {
              ...userStore?.completedSteps,
              hasCompletedOnboarding: true,
            },
          },
        },
        variables: {
          storeId: storeContext.storeId,
          set: {
            completedSteps: {
              ...userStore?.completedSteps,
              hasCompletedOnboarding: true,
            },
          },
        },
      });
    };

    useEffect(() => {
      if (
        userStore &&
        !userStore?.completedSteps?.hasCompletedOnboarding &&
        storeContext?.storeProperties?.plan?.chargeConfirmed
      ) {
        setShowOnboarding(false);
      }
    }, [navigate, userStore, storeContext]);

    useEffect(() => {
      if (userStore?.completedSteps?.hasCompletedOnboarding && showOnboarding)
        setShowOnboarding(false);
    }, [userStore, showOnboarding]);

    useEffect(() => {
      if (
        !storeContext?.storeProperties?.plan?.chargeConfirmed &&
        storeContext?.storeProperties?.plan?.chargeUrl
      ) {
        setIsChargeModalOpen(true);
      }
    }, [storeContext, navigate]);

    useEffect(() => {
      if (
        Boolean(
          userStore &&
            storeContext?.storeProperties &&
            !storeContext?.storeProperties?.plan?.chargeConfirmed &&
            !storeContext?.storeProperties?.plan?.chargeUrl &&
            !storeContext?.storeProperties?.uninstalledAt,
        )
      ) {
        setIsChargeModalOpen(true);
      }
    }, [navigate, storeContext, userStore]);

    const mainHeader = userContext?.announcement;

    const urlsWithoutYPadding = ['/referral-program'];

    return (
      <>
        <div>
          {adminContext?.admin?.user?.user?.id && (
            <AnnouncementHeader className="md:ml-0 bg-red-500">
              <Button
                onClick={() => {
                  if (userContext?.setUser && adminContext?.admin?.user?.user) {
                    userContext.setUser({
                      ...adminContext.admin.user.user,
                      roles: [userRoles.SHIPAID_ADMIN],
                      defaultRole: userRoles.SHIPAID_ADMIN,
                    });
                  }
                  if (
                    storeContext?.updateUserStore &&
                    adminContext.admin.store
                  ) {
                    storeContext.updateUserStore(adminContext.admin.store);
                  }
                  localStorage.removeItem(
                    process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
                  );
                  let redirectUrl = 'merchants';
                  if (userContext?.user?.id) {
                    redirectUrl += `/${userContext.user.id}`;
                  }
                  navigate(redirectUrl);
                }}
                className="ml-auto text-black bg-white bg-gray-100 hover:bg-gray-200"
                size={SizeEnum.xs}
              >
                Exit God Mode
              </Button>
            </AnnouncementHeader>
          )}
          {isChargeModalOpen && (
            <MerchantChargeModal
              isOpen={isChargeModalOpen}
              onClose={() => setIsChargeModalOpen(false)}
            />
          )}
          {isUpdateAppInstallModalOpen && (
            <UpdateAppInstallModal
              isOpen={isUpdateAppInstallModalOpen}
              onClose={() => setIsUpdateAppInstallModalOpen(false)}
            />
          )}
          <div className="flex">
            <SideBar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
            <div className="w-full md:mr-64 mr-0">
              {mainHeader?.text?.length ? (
                <AnnouncementHeader className="bg-indigo-700 text-white">
                  <ReactMarkdown
                    className="reactMarkdown"
                    rehypePlugins={[rehypeRaw]}
                  >
                    {mainHeader.text.toString()}
                  </ReactMarkdown>
                </AnnouncementHeader>
              ) : null}
            </div>
          </div>
          <div>
            <div className="md:pl-64 flex flex-col flex-1 relative">
              {showOnboarding && (
                <OnboardingWizard
                  closeWizard={(shouldCompleteOnboarding: boolean) => {
                    setShowOnboarding(false);
                    shouldCompleteOnboarding && completeStoreOnboarding();
                  }}
                />
              )}
              <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
                <button
                  type="button"
                  className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <main className="flex-1">
                <div
                  className={`${
                    urlsWithoutYPadding.includes(location.pathname)
                      ? 'py-0'
                      : 'py-6'
                  }`}
                >
                  <Routes>
                    <Route
                      index
                      element={<Navigate to="dashboard" replace={true} />}
                    />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="claims" element={<Claims />} />
                    <Route path="claims/new-claim" element={<NewClaim />} />
                    <Route path="claims/:claimId" element={<SingleClaim />} />
                    <Route path="orders" element={<Orders />} />
                    <Route path="settings/*" element={<Settings />} />
                    <Route
                      path="order-settings/*"
                      element={<OrderSettings />}
                    />
                    <Route path="billing" element={<Billings />} />
                    <Route
                      path="referral-program"
                      element={<ReferralProgram />}
                    />
                    <Route path="components" element={<Components />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </div>
              </main>
            </div>
          </div>
        </div>
      </>
    );
  },
);
