import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  DocumentDuplicateIcon,
  XIcon,
  AdjustmentsIcon,
  ShoppingBagIcon,
  HomeIcon,
  ShieldCheckIcon,
  ChartSquareBarIcon,
  UserGroupIcon,
  DocumentReportIcon,
  BellIcon,
  UsersIcon,
  UserCircleIcon,
  ShoppingCartIcon,
  CashIcon,
  DotsVerticalIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  InboxIcon,
  ArrowSmRightIcon,
  SparklesIcon,
} from '@heroicons/react/outline';
import { Dropdown } from 'components/Dropdown';
import { SidebarNavLink } from './SidebarNavLink';
import { SidebarNavDiv } from './SidebarNavDiv';
import { useUser } from 'context/user-context';
import { useStore } from 'context/store-context';
import { Text } from './Text';
import { useSignOut } from '@nhost/react';
import { SelectStoreModal } from './SelectStoreModal';
import { GET_ORDERS_CLAIMS_COUNT } from 'gql/queries';
import { isShipaidAdmin } from 'utils';
import classNames from 'classnames';

type SidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
};

export const SideBar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const adminData = localStorage.getItem(
    process.env.REACT_APP_ADMIN_STORAGE_KEY || 'admin-context',
  );
  let adminContext: any;
  if (adminData) adminContext = JSON.parse(adminData);

  const [claimsCount, setClaimsCount] = useState(0);
  const [isStoreModalOpen, setIsStoreModalOpen] = useState<null | boolean>(
    null,
  );
  const { user } = useUser();
  const isUserShipaidAdmin = isShipaidAdmin(user);
  const store = useStore();
  const { signOut } = useSignOut(true);

  const [getTotalOrderandClaims] = useLazyQuery(GET_ORDERS_CLAIMS_COUNT, {
    onCompleted: (data) => {
      setClaimsCount(data.totalClaims?.aggregate.count);
    },
  });

  useEffect(() => {
    if (store.storeId && store.storeDomain && !isUserShipaidAdmin) {
      getTotalOrderandClaims({
        variables: {
          storeId: store.storeId,
        },
      });
    }
  }, [
    getTotalOrderandClaims,
    store.storeId,
    store.storeDomain,
    isUserShipaidAdmin,
  ]);

  const prodNav = [
    {
      name: 'Dashboard',
      icon: HomeIcon,
      to: 'dashboard',
      count: 0,
      key: 'dashboard',
    },
    {
      name: 'Orders',
      icon: ShoppingBagIcon,
      to: 'orders',
      count: '0',
      key: 'orders',
    },
    {
      name: 'Issues',
      icon: DocumentDuplicateIcon,
      to: 'claims',
      count: claimsCount,
      key: 'claims',
    },
    {
      name: 'Settings',
      icon: AdjustmentsIcon,
      to: 'settings',
      key: 'settings',
      children: [
        {
          name: 'Guarantee',
          icon: ShieldCheckIcon,
          to: 'settings/protection',
          key: 'protection',
        },
        {
          name: 'Order Edit',
          icon: ShoppingCartIcon,
          to: 'order-settings',
          key: 'order-edit',
        },
        {
          name: 'Resolution Portal',
          icon: OfficeBuildingIcon,
          to: 'settings/customer',
          key: 'customer',
        },
        {
          name: 'Notifications',
          icon: BellIcon,
          to: 'settings/notifications',
          key: 'notifications',
        },
        {
          name: 'Tracking',
          icon: InboxIcon,
          to: 'settings/tracking-templates',
          key: 'tracking-templates',
        },
        {
          name: 'Automations',
          icon: SparklesIcon,
          to: 'settings/claim-automations',
          key: 'claim-automations',
        },
        { name: 'Team', icon: UserGroupIcon, to: 'settings/team', key: 'team' },
        {
          name: 'Account',
          icon: DocumentReportIcon,
          to: 'settings/account',
          key: 'account',
        },
        {
          name: 'Install',
          icon: ShoppingBagIcon,
          to: 'settings/install',
          key: 'install',
        },
        {
          name: 'Integrations',
          icon: ArrowSmRightIcon,
          to: 'settings/integration',
          key: 'integration',
        },
      ],
    },
    { name: 'Billing', icon: CashIcon, to: 'billing', key: 'billing' },
    {
      name: 'Refer & Earn',
      icon: UserGroupIcon,
      to: 'referral-program',
      key: 'referral_program',
    },
  ];

  const navigation =
    process.env.NODE_ENV === 'production'
      ? prodNav
      : [
          ...prodNav,
          {
            name: 'All Components',
            icon: ChartSquareBarIcon,
            to: 'components',
            key: 'components',
          },
        ];

  const adminNavigation = [
    { name: 'Merchants', icon: UsersIcon, to: 'merchants', key: 'merchants' },
    { name: 'Admins', icon: UserCircleIcon, to: 'admins', key: 'admins' },
    {
      name: 'Announcements',
      icon: BellIcon,
      to: 'announcements',
      key: 'announcements',
    },
  ];

  const acccountMenuOptions = [
    {
      key: 'account',
      render: (
        <Link
          className="flex items-center p-3 hover:bg-slate-50 cursor-pointer"
          to="/settings/account"
        >
          <CogIcon className="h-5 w-5 mr-2" />
          <Text value="Account" type="sm" />
        </Link>
      ),
    },
    {
      key: 'select-store',
      render: (
        <div
          className="flex items-center p-3 hover:bg-slate-50 cursor-pointer"
          onClick={() => {
            return setIsStoreModalOpen(true);
          }}
        >
          <ShoppingCartIcon className="h-5 w-5 mr-2" />
          <Text value="Select Store" type="sm" />
        </div>
      ),
    },
    {
      key: 'support',
      render: (
        <span
          className="flex items-center p-3 hover:bg-slate-50 cursor-pointer"
          onClick={() =>
            typeof window !== 'undefined' &&
            window.$crisp &&
            window.$crisp?.push(['do', 'chat:open'])
          }
        >
          <QuestionMarkCircleIcon className="h-5 w-5 mr-2" />
          <Text value="Support" type="sm" />
        </span>
      ),
    },
    {
      key: 'logout',
      render: (
        <div
          className="flex items-center p-3 hover:bg-slate-50 cursor-pointer"
          onClick={() => signOut(true)}
        >
          <LogoutIcon className="h-5 w-5 mr-2" />
          <Text value="Logout" type="sm" />
        </div>
      ),
    },
  ];

  const adminAcccountMenuOptions = [
    {
      key: 'account',
      render: (
        <Link
          className="flex items-center p-3 hover:bg-slate-50 cursor-pointer"
          to="/admin/account"
        >
          <CogIcon className="h-5 w-5 mr-2" />
          <Text value="Account" type="sm" />
        </Link>
      ),
    },
    {
      key: 'logout',
      render: (
        <div
          className="flex items-center p-3 hover:bg-slate-50 cursor-pointer"
          onClick={() => signOut(true)}
        >
          <LogoutIcon className="h-5 w-5 mr-2" />
          <Text value="Logout" type="sm" />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={classNames(
                'relative flex-1 flex flex-col max-w-xs w-full bg-white',
                adminContext?.admin?.user?.user?.id && 'mt-24',
              )}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={require('assets/images/shipaid_text_logo.png')}
                    alt="Shipaid"
                    height={32}
                    width={156}
                  />
                </div>
                <nav className="mt-5 space-y-1">
                  {(isUserShipaidAdmin ? adminNavigation : navigation).map(
                    (item: any) =>
                      item ? (
                        <Fragment key={item?.name}>
                          {item.children ? (
                            <SidebarNavDiv item={item} />
                          ) : (
                            <SidebarNavLink
                              item={item}
                              setSidebarOpen={setSidebarOpen}
                            />
                          )}
                        </Fragment>
                      ) : null,
                  )}
                </nav>
              </div>
              <div className="flex-shrink-0 flex justify-between border-t border-gray-200 p-4">
                <button className="flex-shrink-0 w-fit group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={user?.avatarUrl}
                        alt="auth-user"
                        referrerPolicy="no-referrer"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {user?.displayName}
                      </p>
                      <Link
                        to={
                          isUserShipaidAdmin
                            ? '/admin/account'
                            : '/settings/account'
                        }
                        className="text-xs font-medium text-blue-700 group-hover:text-blue-800"
                      >
                        View profile
                      </Link>
                    </div>
                  </div>
                </button>
                <div className="">
                  <Dropdown
                    icon={<DotsVerticalIcon className="h-7 cursor-pointer" />}
                    options={
                      isUserShipaidAdmin
                        ? adminAcccountMenuOptions
                        : acccountMenuOptions
                    }
                    onSelectOption={() => null}
                    btnClassName="p-0 hover:bg-transparent w-fit"
                    itemsClassName="bottom-10 right-0 w-48"
                  />
                </div>
              </div>
              <div className="flex justify-center items-center pb-2">
                <a
                  href="https://www.shipaid.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    value="Terms of Service"
                    type="xs"
                    className="text-gray-500 mr-2"
                  />
                </a>
                <Text value=" | " className="text-gray-500 mr-2" />
                <a
                  href="https://www.shipaid.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    value="Privacy Policy"
                    type="xs"
                    className="text-gray-500"
                  />
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          'hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-10',
          adminContext?.admin?.user?.user?.id && 'mt-8',
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="h-8 w-auto"
                src={require('assets/images/shipaid_logo_300x300.webp')}
                alt="Shipaid"
                height={32}
                width={32}
              />
            </div>
            <nav className="mt-5 flex-1 bg-white space-y-1">
              {(isUserShipaidAdmin ? adminNavigation : navigation).map(
                (item: any) =>
                  item ? (
                    <Fragment key={item.name}>
                      {item.children ? (
                        <SidebarNavDiv item={item} />
                      ) : (
                        <SidebarNavLink item={item} />
                      )}
                    </Fragment>
                  ) : null,
              )}
            </nav>
          </div>
          <div className="flex-shrink-0 justify-between flex border-t border-gray-200 p-4">
            <span className="flex-shrink-0 w-fit group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={user?.avatarUrl}
                    alt="auth-user"
                    referrerPolicy="no-referrer"
                  />
                </div>
                <div className="ml-3 sm:w-32">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 truncate">
                    {user?.displayName}
                    <small className="block">
                      {
                        store.userStores?.find(
                          (s) => store.storeId === s.storeId,
                        )?.name
                      }
                    </small>
                  </p>
                  <Link
                    to={
                      isUserShipaidAdmin
                        ? '/admin/account'
                        : '/settings/account'
                    }
                    className="text-xs font-medium text-blue-700 group-hover:text-blue-800"
                  >
                    View profile
                  </Link>
                </div>
              </div>
            </span>

            <div className="">
              <Dropdown
                icon={<DotsVerticalIcon className="h-7  cursor-pointer" />}
                options={
                  isUserShipaidAdmin
                    ? adminAcccountMenuOptions
                    : acccountMenuOptions
                }
                onSelectOption={() => null}
                btnClassName="p-0 hover:bg-transparent w-fit"
                itemsClassName="bottom-10 w-48"
              />
            </div>
          </div>
          <div className="flex justify-center items-center pb-2">
            <a
              href="https://www.shipaid.com/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              <Text
                value="Terms of Service"
                type="xs"
                className="text-gray-500 mr-2"
              />
            </a>
            <Text value=" | " className="text-gray-500 mr-2" />
            <a
              href="https://www.shipaid.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <Text
                value="Privacy Policy"
                type="xs"
                className="text-gray-500"
              />
            </a>
          </div>
        </div>
      </div>
      <SelectStoreModal
        isOpen={isStoreModalOpen}
        onClose={() => setIsStoreModalOpen(false)}
      />
    </div>
  );
};
